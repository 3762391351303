// @use "reset";
@use "design-tokens";
@use "typography";
@use "animation";
@use "atoms";

body {
  //margin-top: 240px;
  // padding-bottom: 500px;
  transition: all 0.5s ease;
}

body,
main {
  // width: min-content;
}

// a {
//   color: var(--default-link-color);
// }

// * > p:first-of-type {
// 	margin-top: 0 !important;
// 	margin-left: 0 !important;
// }
// * > p:last-of-type {
// 	margin-bottom: 0 !important;
// 	margin-right: 0 !important;
// }

.playground {
  background-color: #504c70;
}

label {
  font-weight: var(--ui-label-weight);
}

.warning {
  color: var(--ui-colors-orange);
}

/* .checkbox {
  border: none;
  background-color: transparent;
  width: 100%;
  //max-width: 600px;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.75rem 2rem;
  cursor: pointer;
  border-radius: 40px;
  transition: all 0.5s ease;
  margin-left: -2rem;
  color: var(--checkbox-text-color);
  background-color: rgb(255, 243, 135, 0.025);
  white-space: pre-wrap;

  @media screen and (max-width: 700px) {
    margin-left: 0;
  }

  &:not(.no-hover) {
    &:hover {
      background-color: var(--checkbox-hover-color);
      color: #000;
    }
  }

  &.no-hover {
    cursor: default;
    pointer-events: all;
  }

  &.active {
    background-color: var(--checkbox-hover-color);
    color: #000;
  }
} */

.app-header {
  width: 100%;
  padding: 20px 0 40px;
  border-bottom: solid 1px var(--border-color);
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  z-index: 4;
  margin-bottom: 4rem;

  p {
    margin-left: 1rem;
    max-width: 80%;
  }

  button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: var(--ui-colors-pink);
    border: none;
  }

  &::before {
    content: attr(data-section);
    position: absolute;
    top: -2rem;
    left: -3rem;
    z-index: -1;
    transition: all 0.5s ease;
    font-size: 8rem;
    color: var(--ui-colors-pink);
    opacity: 0.4;
    font-weight: bold;
  }

  h1 {
    font-size: 4.75rem;

    @media screen and (max-width: 1300px) {
      font-size: 2.5rem;
      line-height: 1;
    }
  }

  &.no-underline {
    border-bottom: none;
  }

  p {
    width: 65ch;
    max-width: 70% !important;
  }

  h1,
  p {
    opacity: 0.4;
  }
}

.the-word {
  color: rgba(131, 109, 219, 1);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;
}

.unlocked {
  h1,
  p {
    opacity: 1;
  }
}

.or:before {
  content: "";
  position: absolute;
  display: block;
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  height: 1px;
  background-color: #fff;
  z-index: 0;
  opacity: 0.4;
}

.or {
  text-align: center;
  margin: 1rem auto;
  position: relative;
  display: block;
  width: 600px;

  span {
    background-color: var(--background-color);
    background-blend-mode: multiply;
    padding: 1rem;
    position: relative;
    z-index: 2;
    font-size: 1.5rem;
  }
}

.tabs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.three {
    grid-template-columns: 1fr 1fr 1fr;
  }

  button {
    display: block;
    width: 100%;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    &:hover {
      background-color: #444;
    }

    &.active {
      background-color: var(--button-secondary-background);
      color: #000;
    }
  }
}

.idea {
  font-size: 1.5rem;
  p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

@keyframes come-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.stream-block {
  opacity: 0;
  animation: come-in 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  // animation-delay: 0.25s;
  transform: translateY(40px);
}

.stream {
  height: 100.001vh;
}

.stream > * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}

.compress {
  letter-spacing: -0.1rem;
}

[data-theme="app"] {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100dvh;
  padding-top: 167px;

  @media screen and (max-width: 640px) {
    padding-top: 143px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 120px;
  }

  @media screen and (max-width: 400px) {
    padding-top: 100px;
  }
}

[data-theme="site"] {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100dvh;
  padding-top: 104px;
}

.bubble-top {
  border-top-left-radius: 33.125rem;
  border-top-right-radius: 33.125rem;
}

.bubble-bottom {
  border-bottom-left-radius: 33.125rem;
  border-bottom-right-radius: 33.125rem;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.gutter {
  margin: 0 3rem;
}

.ml-gutter {
  margin-left: 3rem;
}

.padding-gutter {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-gutter-r {
  padding-right: 3rem;

  @media screen and (max-width: 767px) {
    padding-right: 1rem;
  }
}

.border-tb {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.border-t {
  border-top: 1px solid #000;
}

.border-b {
  border-bottom: 1px solid #000;
}

.skip-to-the-end {
  grid-template-columns: 1fr 1fr;
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}

@media screen and (max-width: 1120px) {
  .name-svg {
    display: none;
  }
}

.section-1 {
  grid-template-columns: 1fr 3fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-2 {
  grid-template-columns: 1fr 1fr 2fr 240px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
  }
}

.section-3 {
  grid-template-columns: 240px auto 700px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-4 {
  grid-template-columns: 2fr 2fr 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-6 {
  grid-template-columns: 2fr 1fr 2fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-7 {
  grid-template-columns: 520px 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-8 {
  grid-template-columns: 250px clamp(400px, 35vw, 800px) auto;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-9 {
  grid-template-columns: 525px 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

.section-10 {
  grid-template-columns: 525px 1fr 240px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

.intro-text {
  &.fade-out {
    animation: fade-out 1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  }
}

@keyframes scroll-down-bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.arrow {
  animation: scroll-down-bounce 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.menu-button {
  display: none;
}

@media screen and (max-width: 960px) {
  .menu-button {
    display: flex;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  .menu-button {
    display: none;
  }
  //lg:flex-col gap-4 main-nav flex flex-col-reverse justify-center lg:justify-normal fixed lg:relative left-0 lg:left-auto top-0 lg:top-auto w-full lg:w-auto h-screen lg:h-auto bg-[#000] lg:bg-transparent transition-all duration-500 ease-in-out
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 105px !important;
    width: 100%;
    height: 100dvh;
    background-color: #000;
    transition: all 0.5s ease;
    z-index: 100;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-100%);
    opacity: 0;

    .menu-button {
      display: block;
    }

    &.open {
      transform: translateX(0);
      opacity: 1;
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      a {
        font-size: 2rem;
        font-weight: 900;
        text-align: center;
        line-height: 0.8;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.menu-btn {
  display: none;
  @media screen and (max-width: 960px) {
    animation: pulse 2s infinite;
    display: block;
    position: fixed;
    right: 20px;
    top: 0.75rem;
    z-index: 100;
    // background-color: #000;
    border: none;
    padding: 0.75rem;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(0deg);
    &:hover {
      background-color: #111;
    }
  }
}

.profile-layout {
  grid-template-columns: max-content auto;

  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.ideas-grid {
  column-count: 2;
  column-gap: 2rem;
  column-fill: balance;

  @media screen and (max-width: 767px) {
    column-count: 1;
  }

  & > * {
    break-inside: avoid-column;
    margin-bottom: 2rem;
    display: inline-block;
    width: 100%;
  }
}

.action-bar {
  grid-template-columns: auto max-content;
}

.footer {
  .footer-video {
    position: relative;
  }

  .footer-text {
    position: absolute;
  }

  @media screen and (max-width: 767px) {
    .footer-video {
      position: absolute;
    }

    .footer-text {
      position: relative;
    }
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 1000;
}

.profile-menu {
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: scaleIn 0.25s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-outline {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
}

.case-grid {
  display: grid;
  grid-template-columns: 300px auto 300px;
  gap: 2rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0 1rem;
    align-items: center;
  }
}

.crazy-buttons {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 0;
  }

  &.light {
    &::before {
      background-color: #ccc;
    }
  }

  button {
    position: relative;
    z-index: 1;
  }
}

.custom-input {
  display: flex;
  position: relative;
  /* padding-left: 50px; */
  cursor: pointer;
  // user-select: none;
  font-weight: 400;

  &:has(input:disabled) {
    opacity: 0.75;
  }

  span {
    line-height: 1.5;
  }
}

.custom-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  /* position: absolute;
  top: 0;
  left: 0; */
  position: relative;
  height: 40px;
  width: 40px;
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border: 2px solid #ccc;
  box-sizing: border-box;
}

.custom-input:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-input input:checked ~ .checkmark {
  background-color: var(--ui-colors-pink);
  border-color: var(--ui-colors-pink);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-input input:checked ~ .checkmark:after {
  display: block;
}

.custom-input .checkmark.radio {
  border-radius: 50%;
}

.custom-input .checkmark.radio:after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
}

.custom-input .checkmark.checkbox:after {
  left: 14px;
  top: 7px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.admin-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;

  thead {
    background-color: #000;
    color: #fff;
  }

  th,
  td {
    padding: 10px;
    text-align: left;
  }
}

.app {
  width: 100vw;
  height: 100vh;
}

.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.canvas-card {
  // &:before {
  //   box-shadow: -2px 2px 15px 0 rgba(0, 0, 0, 0.5);
  //   // filter: drop-shadow(-10px 2px 15px rgba(0, 0, 0, 0.25));
  //   background-color: rgba(0, 0, 0, 0.25);
  //   content: "";
  //   width: 90%;
  //   left: 10px;
  //   transform: rotate(deg);
  //   height: 65%;
  //   position: absolute;
  //   top: 22%;
  //   z-index: 0;
  // }
  .content {
    padding: 20px;
    width: 250px;
    height: 250px;
    position: relative;
    background-color: #fff;
    // clip-path: url(#stickyClip);

    &.chosen {
      padding: 32px 16px;
    }

    p {
      font-size: 1rem;
      font-weight: 800;
    }
  }

  &.idea {
    .content {
      background-color: var(--ui-colors-pink);
      &.chosen {
        background-color: hsl(311 81% 55% / 1);
      }
      p {
        color: #fff;
      }
    }

    &.selected {
      .content {
        background-color: #cf4c67;
      }
    }
  }

  &.paper {
    &:before {
      display: none;
    }
    .content {
      background-color: var(--ui-colors-yellow);
      height: auto;
      width: 500px;
      clip-path: none;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &.challenge {
    .content {
      background-color: var(--ui-colors-purple);
      p {
        color: #fff;
      }

      &.chosen {
        background-color: hsl(252deg 60.44% 55%);
      }
    }
  }

  &.metaphor {
    .content {
      background-color: var(--ui-colors-yellow);
      p {
        color: #000;
      }
    }

    &.selected {
      .content {
        background-color: #f2e718;
      }
    }
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 100000000005 !important;
}

.challenge-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;

  gap: 2rem;
  padding: 2rem;

  .challenge-card {
    width: 100%;
  }

  @media screen and (max-width: 832px) {
    display: flex;
    flex-direction: column;
    .challenge-card {
      width: 100%;
    }
  }
}

.add-tags {
  span {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
}

.tag {
  cursor: pointer;
  svg {
    transition: opacity 0.2s ease;
    opacity: 0;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.news-grid {
  grid-template-columns: 500px auto;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}

.sall-e-helper {
  display: block;
  width: 100px;
  height: 100px;
  @media screen and (min-width: 768px) {
    width: 150px;
    height: 150px;
  }
  transform: translateY(5px);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(0);
  }
}

.max-w-content {
  @media screen and (min-width: 768px) {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }
}

.narrow-container {
  max-width: 100%;
  width: 600px;

  @media screen and (min-width: 768px) {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.small-container {
  max-width: 100%;
  width: 800px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;

    &.no-margin {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.text-gray {
  color: #6e6e6e;
}
